 // !! DO NOT EDIT THIS FILE DIRECTLY !!
 // !! It will be overrideen by gulp !!
 //    Please use global/scripts.js

"use strict";

/**
* @file cookie.js
* Generic cookie handling functions.
* @author Hal Burgiss 2009-03-22
*/
function setCookie(name, value, days) {
     if (days) {
          var date = new Date();
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
          var expires = "; expires=" + date.toGMTString();
     } else var expires = "";
     document.cookie = name + "=" + value + expires + "; path=/";
}
function readCookie(name) {
     var nameEQ = name + "=";
     var ca = document.cookie.split(';');
     for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
               c = c.substring(1, c.length);
          }if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
     }
     return null;
}
function eraseCookie(name) {
     setCookie(name, "", -1);
}
 // !! DO NOT EDIT THIS FILE DIRECTLY !!
 // !! It will be overrideen by gulp !!
 //    Please use global/scripts.js

'use strict';

/**
 * Slate Mobile Navigation Menu
 *
 */
(function () {

	'use strict';

	/**
  * 'is_mobile' variable to determine if this is a mobile size.
  */

	var set_is_mobile,
	    is_mobile = false;

	window.addEventListener('load', function () {
		var menuToggle = document.getElementById('menu-toggle');

		(set_is_mobile = function set_is_mobile() {
			if (window.getComputedStyle(menuToggle).display === "none") {
				is_mobile = false;
			} else {
				is_mobile = true;
			}
		})();

		/**
   * Toggle menu open/closed when clicking menu toggle
   */
		menuToggle.addEventListener('click', function () {
			this.classList.toggle('open');
			document.getElementById('menu').classList.toggle('open');
			document.querySelector('body').classList.toggle('opened-menu');

			if (this.classList.contains('open')) {
				this.setAttribute('aria-expanded', true);
			} else {
				this.setAttribute('aria-expanded', false);
			}
		});
	});

	var subToggles = document.querySelectorAll('.submenu-toggle');

	for (var i = 0; subToggles.length > i; i++) {
		var focus_out_timer = null;
		var toggle_button = subToggles[i];

		toggle_button.addEventListener('click', function () {
			this.classList.toggle('open');
			this.parentNode.classList.toggle('open');

			if (this.classList.contains('open')) {
				this.setAttribute('aria-expanded', true);
			} else {
				this.setAttribute('aria-expanded', false);
			}
		});
	}

	var sidebarToggles = document.querySelectorAll('.sidebar-toggle');

	if (sidebarToggles.length > 0) {

		for (var i = 0; sidebarToggles.length > i; i++) {
			var focus_out_timer = null;
			var sidebarButton = sidebarToggles[i];

			sidebarButton.addEventListener('click', function () {
				if (this.classList.contains('open-on-load')) {
					this.classList.remove('open-on-load');
					this.parentNode.classList.remove('open-on-load');

					if (window.innerWidth < 767) {
						this.classList.toggle('open');
						this.parentNode.classList.toggle('open');
					}
				} else {
					this.classList.toggle('open');
					this.parentNode.classList.toggle('open');

					if (this.classList.contains('open')) {
						this.setAttribute('aria-expanded', true);
					} else {
						this.setAttribute('aria-expanded', false);
					}
				}
			});
		}
	}
})();
 // !! DO NOT EDIT THIS FILE DIRECTLY !!
 // !! It will be overrideen by gulp !!
 //    Please use global/scripts.js

"use strict";

/**
 * This file is loaded asyncronously in the footer.
 * All custom global scripts should be included here.
 */

/*
	A little info for this js file:

	As much as possible, this file should be kept organized. Currently, two major divisions are seen below, delinitated by their respective functions of "on load" and "ready".
		As mentioned below, most code added to this file should be included within the "on load" function.

	For ease of future use, include any new code at the bottom of the relevant section of the page, adding clear comments to explain the purpose of the code being written,
		including any relavant details regarding what the code overrides, etc.
	*/

(function () {
  "use strict";

  // rerun lazyload on screen size updates

  window.addEventListener("resize", function () {
    dbs.lazyload();
  });

  // Load event function ... most custom stuff goes here. Safer for async.
  window.addEventListener("load", function () {
    /* Search Form functionality
     * The click functionality involves three different actions,
     * depending on conditions the various clicks provide.
     */
    var searchOpen = document.querySelectorAll(".search-form__button--open");
    var searchClose = document.querySelectorAll(".search-form__button--close");

    if (searchOpen) {
      for (var i = 0; searchOpen.length > i; i++) {
        searchOpen[i].addEventListener("click", function () {
          this.parentElement.classList.add("search-form--active");
          this.parentElement.querySelector(".site_search__field").focus();

          // Hide the search bar after click out of it
          document.getElementById("content").addEventListener("click", function () {
            if (this.parentElement.classList.contains("search-form--active")) {
              this.parentElement.classList.remove("search-form--active");
            }
          });
        });
      }

      for (var i = 0; searchClose.length > i; i++) {
        searchClose[i].addEventListener("click", function () {
          this.parentElement.classList.remove("search-form--active");
        });
      }
    }

    /**
     * Linkclicker
     *
     * A class placed on a wrapping element with a link inside to make the wrapper clickable.
     * Note: the container should have only one link.
     */
    var linkclickers = document.querySelectorAll(".linkclicker");

    for (var i = 0; linkclickers.length > i; i++) {
      var linkclicker = linkclickers[i];

      linkclicker.addEventListener("click", function (e) {
        e.preventDefault();
        var link = this.querySelector("a");
        var linkPath = link.getAttribute("href");

        if (link.getAttribute("target", "_blank")) {
          window.open(linkPath);
        } else {
          window.location.href = linkPath;
        }
      });
    }

    /**
     * Force external links/PDFs to open in a new tab.
     * From https://stackoverflow.com/questions/2910946/test-if-links-are-external-with-jquery-javascript
     * 2019-07-11 - TA
     */
    function link_is_external(link_element) {
      return link_element.host !== window.location.host || link_element.href.indexOf("pdf") > -1;
    }

    var anchorElements = document.getElementsByTagName("a");
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = anchorElements[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var anchorElement = _step.value;

        if (link_is_external(anchorElement)) {
          anchorElement.setAttribute("target", "_blank");
          anchorElement.setAttribute("rel", "noopener");

          // Add assistive text to alert of external links
          var assistiveText = '<span class="assistive">. External Link. Opens in new window.</span>';
          var assistiveTextContainer = document.createElement("span");
          assistiveTextContainer.innerHTML = assistiveText;
          anchorElement.appendChild(assistiveTextContainer.firstChild);
        }
      }

      /**
       * Detects if element is in viewport
       *
       * @usage
       * var el = document.querySelector('.element-class');
       *
       * window.addEventListener('scroll', function(event) {
       * 		if (isInViewport(el)) {
       *			el.classList.add("in-view");
       *		}
       * }, false);
       *
       * NOTE: If the whole layout is taller than the viewport, this will never fire. It is often
       * better to target one small element inside a layout, or put a small hidden placeholder
       * element high in the layout that can be a sibling to the wrapping element so it can be
       * targeted.
       */
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    function isInViewport(elem) {
      var rect = elem.getBoundingClientRect();
      return rect.top < window.innerHeight && rect.bottom > 0 && rect.left < window.innerWidth && rect.right > 0;
    }

    var els = document.querySelectorAll(".animated");

    function checkVisibility() {
      els.forEach(function (el) {
        if (isInViewport(el)) {
          el.classList.add("in-view");
        }
      });
    }

    // Initial check in case elements are already visible
    checkVisibility();

    // Listen to scroll and resize events for ongoing checks
    window.addEventListener("scroll", checkVisibility, { passive: true });
    window.addEventListener("resize", checkVisibility, { passive: true });

    /**
     * Collapsibles
     */
    var collapsibleHeaders = document.querySelectorAll(".collapsible-row__header");

    if (collapsibleHeaders) {
      for (var i = 0; collapsibleHeaders.length > i; i++) {
        var collapsibleHeader = collapsibleHeaders[i];

        collapsibleHeader.addEventListener("click", function () {
          this.classList.toggle("active");
          var textHeight = this.nextElementSibling.querySelector(".collapsible-row__content").clientHeight; // Get height of text within collapsible row
          var rowHeight = this.nextElementSibling.clientHeight; // Get height of row to see if it's expanded

          if (rowHeight == 0) {
            // If collapsible content is collapsed...
            this.nextElementSibling.style.height = textHeight + 1 + "px";
          } else {
            this.nextElementSibling.style.height = 0 + "px";
          }

          if (this.parentNode.classList.contains("expanded")) {
            this.setAttribute("aria-expanded", "true");
          } else {
            this.setAttribute("aria-expanded", "false");
          }
        });
      }
    }

    /**
     * Mega Menu
     */
    // Switch through model info based on which sidebar button is hovered/focused
    var buttons = document.querySelectorAll(".mega-menu__list-link");

    for (var i = 0; i < buttons.length; i++) {
      var current = buttons[i];
      current.addEventListener("mouseover", function (event) {
        var target = event.target;
        previewKit(target);
      });
      current.addEventListener("focus", function (target) {
        var target = event.target;
        previewKit(target);
      });
    }

    function previewKit(target) {
      if (target.parentNode.classList.contains("current-item-previewed")) {
        // Do nothing
      } else {
        // Remove current class from button siblings
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = target.parentNode.parentNode.children[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var sibling = _step2.value;

            if (sibling !== this) {
              sibling.classList.remove("current-item-previewed");
            }
          }
          // Give target "current-item-previewed" class
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        target.parentNode.classList.add("current-item-previewed");
        // Get ID from targeted button
        var cardID = target.parentNode.getAttribute("id");

        // Removes current class from card siblings
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = document.querySelector("." + cardID).parentNode.children[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var _sibling = _step3.value;

            if (_sibling !== this) _sibling.classList.remove("current-item-previewed");
          }

          // Display card for targeted button
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        document.querySelector("." + cardID).classList.add("current-item-previewed");
      }
    }

    /**
     * Pre-fills contact form with model info if it exists from query string
     */
    // Step 1: Parse the URL query parameters
    var urlParams = new URLSearchParams(window.location.search);
    var kitParam = urlParams.get('kit');

    if (kitParam) {
      // Step 2: Decode the parameter value
      var decodedKitParam = decodeURIComponent(kitParam);

      // Step 3: Find the checkbox with the matching value
      var checkbox = document.querySelector("input[type=\"checkbox\"][value=\"" + decodedKitParam + "\"]");

      // Step 4: Check the checkbox if it exists
      if (checkbox) {
        checkbox.checked = true;
      }
    }

    /**
     * Form completion event for custom event trigger/goal completion
     *
     * The point of this method is to eliminate false conversions in Analytics
     * due to users refreshing the /thank-you/ page. This will now set a cookie after a form
     * submit event and get the form's id to pass into analytics. The analytics event for a goal
     * conversion is then generated if there a form completion cookie set, and the url is that
     * of a thank-you page. Each form will have a unique conversion event in Analytics based on
     * the formID that gets passed as the event label.
     *
     * There is the assumption that all forms on the site are a Gravity Form, and that
     * they re-direct to a /thank-you/ page of some kind on completion.
     */
    var form = document.querySelector(".freeform-form-container form");

    if (form) {
      form.addEventListener("submit", function (event) {
        var formId = "";
        // Set ID to match old site analytics convention
        // Is this weird? Maybe.
        if (form.classList.contains("contact-form")) {
          formId = "gform_1";
          event.preventDefault();
          var first_name = document.getElementById("form-input-firstName").value;
          var last_name = document.getElementById("form-input-lastName").value;
          var email = document.getElementById("form-input-email").value;
          var phone = document.getElementById("form-input-phone").value;
          var state = document.getElementById("form-input-state").value;
          var plan_to_build = document.getElementById("form-input-when").value;
          var models = document.querySelectorAll(".model-checkbox"); // Select all elements with the class "model-checkbox"
          var dataTitle = [];

          models.forEach(function (model) {
            if (model.checked) {
              dataTitle.push(model.value);
            }
          });

          dataTitle = dataTitle.join(", ");

          var own_land = document.getElementById("form-input-doYouOwnALotOrLandForBuilding-0").checked ? "true" : "false";
          var developer = document.getElementById("form-input-areYouABuilderOrRealtor-0").checked ? "true" : "false";
          var future_offers = document.getElementById("form-input-wouldYouLikeToBeContactedAboutFutureOffersFromMightySmallHomes-0").checked ? "true" : "false";
          var message = document.getElementById("form-input-message").value;

          var getHSValues = function getHSValues() {
            var timestamp = Math.floor(Date.now());
            var hutk_cookie = readCookie("hubspotutk");

            return {
              submittedAt: timestamp,
              fields: [{
                name: "firstname",
                value: first_name
              }, {
                name: "lastname",
                value: last_name
              }, {
                name: "email",
                value: email
              }, {
                name: "phone",
                value: phone
              }, {
                name: "what_state_are_you_planning_to_build_in_",
                value: state
              }, {
                name: "when_do_you_plan_to_build_",
                value: plan_to_build
              }, {
                name: "tell_us_how_we_can_help_you_today_",
                value: message
              }, {
                name: "which_model_are_you_interested_in_",
                value: dataTitle
              }, {
                name: "do_you_own_a_lot_or_land_for_building_",
                value: own_land
              }, {
                name: "are_you_a_builder_developer_realtor_or_investor_",
                value: developer
              }, {
                name: "would_you_like_to_be_contacted_about_future_offers_from_mighty_small_homes_",
                value: future_offers
              }],
              context: {
                hutk: hutk_cookie,
                pageUri: "www.mightysmallhomes.com/contact-us/",
                pageName: "Contact Form"
              }
            };
          };

          var hsPost = function hsPost() {
            var jsonHSObj = getHSValues();
            var endpoint = "https://api.hsforms.com/submissions/v3/integration/submit/6818383/5d8681eb-daa3-4349-a442-44d4dd0830c8";
            var xhr = new XMLHttpRequest();

            xhr.onreadystatechange = function () {
              if (this.readyState == 4) {
                if (this.status == 200) {
                  console.info("HubSpot submission successful");
                } else {
                  console.warn("ERROR! Data was not successfully sent to HubSpot.");
                  console.log(this.responseText);
                }
              }
            };

            xhr.open("POST", endpoint, true);
            xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
            xhr.send(JSON.stringify(jsonHSObj));
          };

          var googlePost = function googlePost() {
            var jsonobj = {
              form_name: "selector",
              firstName: first_name,
              lastName: last_name,
              email: email,
              phone: phone,
              state: state,
              when: plan_to_build,
              model: dataTitle,
              ownLot: own_land,
              builderrealtor: developer,
              wantcontact: future_offers,
              message: message
            };

            try {
              fetch("https://script.google.com/macros/s/AKfycbyNTgpx-QpXOip2rHMFVbOC-eq4XPfxwjBMSfMGgXjZoIcHesBe2gOlX-mivUNCiICt/exec", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
                },
                body: JSON.stringify(jsonobj)
              }).then(function (response) {
                if (!response.ok) {
                  throw new Error("Network response was not ok " + response.statusText);
                }
                return response.json();
              }).then(function (data) {
                console.info("Success", data);
              }).catch(function (error) {
                console.warn("ERROR! Data was not successfully sent.", error);
              });
            } catch (e) {
              console.log(e.message);
              alert("Sorry, an error occurred.");
            }
          };
          var mailchimpPost = function mailchimpPost() {
            var actionUrl = '/index.php?p=actions/toolkit/toolkit/mailchimp';

            var subscriber = {
              email_address: email,
              status: "subscribed",
              merge_fields: {
                FNAME: first_name,
                LNAME: last_name,
                MMERGE3: dataTitle,
                MMERGE4: developer
              }
            };
            fetch(actionUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
              },
              body: JSON.stringify(subscriber)
            }).then(function (response) {
              return response.json();
            }).then(function (data) {
              console.log(data);
            });
          };
          // hsPost();
          // googlePost();
          // mailchimpPost();
          Promise.all([googlePost(), mailchimpPost()]).then(function () {
            setCookie("form-completion", "form-completion-value", 1);
            setCookie("form-id", formId, 1);
            window.location.href = '/thank-you/';
          }).catch(function (error) {
            console.error('Error occurred during API calls:', error);
          });
        } else if (form.classList.contains("basic-contact")) {
          formId = "basic-contact-form";
        } else if (form.classList.contains("builder-program")) {
          formId = "gform_3";
        } else if (form.classList.contains("builder-form")) {
          formId = "quote-builder-form";
        }
        setCookie("form-completion", "form-completion-value", 1);
        setCookie("form-id", formId, 1);
      });
    }

    var formCompleted = readCookie("form-completion");
    var formId = readCookie("form-id");
    var url = window.location.href.indexOf("thank") >= 0; // Not exactly thank-you because the
    // page titles/urls vary, but all contain 'thank'

    if (formCompleted == "form-completion-value" && url) {
      console.log(formCompleted);
      console.log(formId);

      // Create analytics event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "formSubmission",
        formID: formId
      });

      window.uetq = window.uetq || [];
      window.uetq.push("event", "Form Submission", {
        event_label: formId
      });

      // Remove cookies so event cannot be fired again on /thank-you page refresh
      eraseCookie("form-completion");
      eraseCookie("form-id");
    }
  }); // End of load function

  // Include ready events here
  window.addEventListener("DOMContentLoaded", function () {});
})();